import { prefix } from '../utils/default-setting'
// 2020-4-23 17:12:32  一个能解决性能问题和外部拿不到对象问题的下拉框
import pagodaComponents from 'pagoda-ui'
import requestSelect from './form/request-select'
import inlineIframe from './layout/inline-iframe'

let components = {
  requestSelect,
  inlineIframe
}
const install = Vue => {
  for (const component of Object.values(components)) {
    Vue.component(prefix + component.name, component)
  }
}
pagodaComponents.formGroup.registerComponents({
  'request-select': {
    component: requestSelect
  }
})
export default {
  install,
  requestSelect
}
