import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入element-ui 作为依赖
import ElementUi from 'element-ui'
import PagodaUi from 'pagoda-ui'
import 'pagoda-ui/src/theme/theme1.scss'
import 'pagoda-ui/src/theme/ui-reset.scss'
import './assets/style/index.scss'
import componenst from './components'
import services from './service-install'
import store from './store'
import './utils/filter'
import './utils/directive'
import * as date from './utils/date'
import layoutHeader from './components/layout-header'
import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'

VueMarkdownEditor.use(vuepressTheme)
VueMarkdownEditor.use(createEmojiPlugin())
Vue.use(VueMarkdownEditor)

// 初始化构建时间
const consoleStyle =
  'background-color: black;color: #fadd9d;padding: 4px 6px;margin: 10px'
window.$buildTime = process.env.VUE_APP_buildTime
console.log(`%c最近构建时间： ${window.$buildTime}`, consoleStyle)
// 注意：需要在use element-ui 之前 use pagoda-ui
Vue.use(PagodaUi).use(ElementUi, { size: 'mini' })
Vue.use(componenst)
Vue.use(services)
Vue.use(store)
// 全局注册组件
Vue.component('layoutHeader', layoutHeader)

// 防重发指令
Vue.directive('debounce', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 4000)
      }
    })
  }
})

/* 全局注册日期工具方法 */
Vue.prototype.$date = date
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
