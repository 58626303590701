<template>
  <div
    class="erp-request-select"
    :class="{
      'advanced-search': advancedSearch,
      'remote': remote
    }"
  >
    <el-select
      :value="value"
      :class="{
        'is-focus': isFocus
      }"
      :style="{
        'min-width': (remote && multiple) ? '200px' : 'unset'
      }"
      :placeholder="placeholderText"
      default-first-option
      @remove-tag="handleRemoveTag"
      v-bind="$attrs"
      v-on="listeners"
      :multiple="multiple"
      :value-key="valueKey"
      :label-key="labelKey"
      :remote="!!remote"
      :remote-method="handleRemote"
      @visible-change="handleVisibleChange"
      :filterable="!!remote || filterable"
      :disabled="loading || disabled"
      :loading="loading"
      ref="elSelect">
      <template v-if="group">
        <el-option-group
          v-for="group in options"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="option in group.options"
            :key="valueKey ? option[valueKey] : option.value"
            :label="labelKey? option[labelKey] : option.label"
            :value="option"
            :disabled="option.disabled"
          >
            <slot v-bind="option">{{selectSlotCustom(option)}}</slot>
          </el-option>
        </el-option-group>
      </template>
      <el-option
        v-else
        v-for="option in options"
        :key="valueKey ? option[valueKey] : option.value"
        :label="labelKey? option[labelKey] : option.label"
        :value="option"
        :disabled="option.disabled"
      >
        <slot v-bind="option">{{selectSlotCustom(option)}}</slot>
      </el-option>
    </el-select>
    <span
      class="el-input__suffix"
      v-if="advancedSearch"
      v-show="!loading"
      @click.stop="handleAdvancedSearch"
    >
      <span class="el-input__suffix-inner">
        <i class="el-input__icon el-icon-more"></i>
      </span>
    </span>
  </div>
</template>

<script>
/* 2020-4-23 17:11:33 由于async-select和bdc-select嵌套太深，业务性过重，难以扩展和维护，
  现重构一个带高级搜索功能的下拉框request-select，主要解决性能问题和外部拿不到对象的问题 */
import { bounce, isEmpty } from '@/utils'
export default {
  name: 'request-select',
  data () {
    // 代理input 和before-input事件，增加elSelect参数
    let listeners = {
      ...this.$listeners
    }
    const proxyNames = ['input', 'before-input', 'beforeInput']
    proxyNames.forEach(name => {
      const handler = this.$listeners[name]
      if (handler) {
        listeners[name] = (...args) => {
          const elSelect = this.$refs.elSelect
          handler(...args, elSelect)
        }
      }
    })
    return {
      listeners,
      isFocus: false,
      options: [
      ],
      loading: true,
      queryStr: '',
      isfirstRequest: true, // 标记第一次请求
      isfirstFocusRequest: true, // 标记为第一次聚焦
      lastParams: null // 记录最近一次的params
    }
  },
  computed: {
    placeholderText () {
      return this.loading ? this.loadingMsg : this.placeholder
    }
  },
  props: {
    value: null,
    formatSelectSlot: Function,
    multiple: {
      type: Boolean,
      default: false
    },
    valueKey: {
      type: String,
      default: 'code'
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    request: {
      type: Function,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 开启远程搜索
    remote: {
      type: String,
      default: ''
    },
    filterable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    group: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    loadingMsg: {
      type: String,
      default: '选项加载中'
    },
    // 默认是否请求
    firstRequest: {
      type: Boolean,
      default: true
    },
    // 默认不请求聚焦后再请求
    firstFocusRequest: {
      type: Boolean,
      default: false
    },
    // 是否开启高级搜索组件
    advancedSearch: {
      type: Boolean,
      default: false
    },
    advancedProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 默认选中逻辑
    autoSelectFn: Function,
    // 缓存key值，传入代表执行缓存
    cacheKey: String
  },
  watch: {
    params: {
      handler (n, o) {
        if (JSON.stringify(n) === JSON.stringify(this.lastParams)) return
        if (this.firstRequest || !this.isfirstRequest) {
          this.refresh()
        } else {
          this.isfirstRequest = false
          this.loading = false
        }
      },
      immediate: true,
      deep: true
    },
    queryStr: {
      handler (n) {
        this.refresh(n)
      }
    },
    cacheKey: {
      handler (n, o) {
        this.$cacheService.delPromiseSpace(o)
        // 根据cacheKey开辟缓存空间
        this.$cacheService.addPromiseSpace(n)
      }
    }
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
    // 根据cacheKey清除空间, 当count引用为0时, 将会删除整个空间
    this.$cacheService.delPromiseSpace(this.cacheKey)
  },
  methods: {
    handleInput (...args) {
      const elSelect = this.$refs.elSelect
      this.$emit('input', ...args, elSelect)
    },
    handleBeforeInput (data) {
      const elSelect = this.$refs.elSelect
      const resolveInput = (value) => { // value代表外部可决定input值
        this.handleInput(value || data)
      }
      // 模拟before-input
      if (this.$listeners['before-input']) {
        this.$emit('before-input', resolveInput, data, elSelect)
      } else if (this.$listeners['beforeInput']) {
        this.$emit('beforeInput', resolveInput, data, elSelect)
      } else {
        resolveInput()
      }
    },
    // 限制一直执行beforeInput逻辑
    bounceBeforeInput: bounce(function (inputData) {
      this.handleBeforeInput(inputData)
    }, 100),
    // 下拉插槽内容区自定义
    selectSlotCustom (scope) {
      if (this.formatSelectSlot) {
        return this.formatSelectSlot(scope)
      } else {
        return `${scope[this.valueKey]} ${scope[this.labelKey]}`
      }
    },
    handleRemote: bounce(function (queryStr) {
      this.queryStr = queryStr
    }, 180),
    async refresh (queryStr) {
      let params = {
        ...this.params
      }
      this.lastParams = params
      if (this.remote) {
        params[this.remote] = this.queryStr
      }
      let options = await this.$cacheService.getPromiseBySpace(this.cacheKey, this.request, params)
      this.loading = false
      this.options = options
      // 2020-5-23 15:14:45 处理自动选择逻辑 有值的时候不执行
      if (this.autoSelectFn && isEmpty(this.value)) {
        const inputData = this.autoSelectFn(options)
        if (!inputData) return
        setTimeout(() => {
          this.handleInput(inputData)
        }, 0)
      }
      return options
    },
    focus () {
      this.$refs.elSelect.focus()
    },
    // 多选时清空按钮默认清空全部
    handleRemoveTag () {
      for (let key of ['collapse-tags', 'collapseTags']) {
        if (key in this.$attrs && this.$attrs[key] !== false) { // 确保是collapseTags才清空全部
          this.$emit('input', [])
          break
        }
      }
    },
    handleVisibleChange (visible) {
      this.isFocus = visible
      if (visible) {
        // 每次打开重置查询值
        this.queryStr = ''
        // 是否第一次聚焦
        if (this.firstFocusRequest && this.isfirstFocusRequest) {
          this.refresh()
        } else {
          this.isfirstFocusRequest = false
        }
      }
    },
    handleAdvancedSearch () {
      // 禁用状态不能点击高级搜索
      // if (this.disabled) return
      // this.$advance({
      //   multiple: !!this.multiple,
      //   outsideSelection: this.multiple ? this.value : [],
      //   ...this.advancedProps
      // }).then(selectData => {
      //   const data = this.multiple ? selectData : selectData && selectData[0]
      //   this.handleBeforeInput(data)
      // })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus">
.erp-request-select
  position: relative
  display inline-block
  .el-input__suffix
    .el-input__icon
      line-height: 1
      cursor pointer
  /* 高级搜索时样式特殊处理 */
  &.advanced-search
    .el-select .el-input__suffix
      right 25px
</style>
