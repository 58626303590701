/**
 * 管理缓存相关服务
 * 1. 浏览器缓存上次查询条件
 * 2. 需要做缓存的公用接口放这里
 */
import { getlocalStorageObj, setlocalStorageObj, isObject } from '@/utils'
import HttpService from './ajax.service'
const cacheField = 'erp_cache_formdata_'
class CacheService {
  constructor () {
    this.name = 'CacheService'
    // 根据唯一key缓存请求的容器
    this.cacheRequest = {}
    // 带计数器的缓存空间 {cacheKey: {count: 1, [paramsKey]: Promise} }
    this.cachePromiseSpace = {}
  }
  /* 浏览器缓存上次查询条件 start */
  cacheFormData (route, formdata) {
    setlocalStorageObj(`${cacheField}${route.name}`, formdata)
  }
  recoverFormData (route) {
    return getlocalStorageObj(`${cacheField}${route.name}`)
  }
  removeCacheFormData (route) {
    window.localStorage.removeItem(`${cacheField}${route.name}`)
  }
  /* 浏览器缓存上次查询条件 end */

  /* 组装key缓存接口数据 start */
  request (requestData) {
    // const { method = 'get', url, params = null, options = {} } = requestData
    requestData.method = requestData.method || 'get'
    const cacheKey = JSON.stringify(requestData)
    this.cacheRequest[cacheKey] = this.cacheRequest[cacheKey] || HttpService.request(requestData)
    return this.cacheRequest[cacheKey]
  }
  removeCacheRequest (requestData) {
    requestData.method = requestData.method || 'get'
    const cacheKey = JSON.stringify(requestData)
    this.cacheRequest[cacheKey] = null
  }
  /* 组装key缓存接口数据 end */

  /* promise 缓存计数器，引用为0时清除缓存 start */
  // 给cacheKey开辟一个新空间
  addPromiseSpace (cacheKey) {
    if (this.cachePromiseSpace[cacheKey]) {
      this.cachePromiseSpace[cacheKey].count += 1
    } else {
      this.cachePromiseSpace[cacheKey] = {
        count: 1 // 计数器
      }
    }
  }
  /**
   * 根据cacheKey和params设置/读取缓存
   * @param  {[type]} cacheKey [缓存空间唯一标识]
   * @param  {[type]} request  [request函数，必须返回一个promise]
   * @param  {[type]} params   [请求参数]
   * @return {[type]}          [promise]
   */
  getPromiseBySpace (cacheKey, request, params) {
    // 若不缓存直接执行后返回
    if (!cacheKey) return request(params)
    const paramsKey = JSON.stringify(params)
    // console.log(this.cachePromiseSpace[cacheKey])
    if (!this.cachePromiseSpace[cacheKey]) this.addPromiseSpace(cacheKey) // 兼容处理，保证存在缓存空间
    const cachePromiseObj = this.cachePromiseSpace[cacheKey] // 根据cacheKey取空间
    if (!cachePromiseObj[paramsKey]) { // 根据paramsKey设置缓存
      cachePromiseObj[paramsKey] = request(params)
    }
    // 根据paramsKey读缓存
    return cachePromiseObj[paramsKey]
  }
  // 根据cacheKey清除空间, 当count引用为0时, 将会删除整个空间
  delPromiseSpace (cacheKey) {
    const cacheObj = this.cachePromiseSpace[cacheKey]
    if (cacheObj && cacheObj.count > 1) {
      cacheObj.count -= 1
    } else {
      this.cachePromiseSpace[cacheKey] = null
    }
    // console.log(cacheKey, cacheObj)
  }
  /* promise 缓存计数器，引用为0时清除缓存 end */

  /* 查找默认目录的整颗分类树，自带缓存功能 */
  getSelectTreeClassify (type, baseUrl, extraParams = {}) {
    const typeObj = {
      'shangpin': 10,
      'jigou': 20,
      'kehu': 30,
      'gongyingshang': 40,
      'bumen': 50,
      'kufang': 60
    }
    // type为对象时代表自定义接口，这种写法是为了满足当时额外的需求，暂不改动
    if (isObject(type)) {
      // return HttpService[type.method.toLowerCase()](baseUrl + type.url, type.params, { timeout: 120000 })
      return this.request({
        method: type.method.toLowerCase(),
        url: baseUrl + type.url,
        params: type.params,
        options: { timeout: 120000 }
      })
    }
    return this.request({
      method: 'get',
      url: baseUrl + '/CatClassCommonService/findDefaultClassTree',
      params: { tableId: typeObj[type], ...extraParams },
      options: { timeout: 120000 }
    })
  }
}
export default new CacheService()
