export const prefix = 'mvp-'
// 权限系统标记
export const RBAC_TAG = 'rbac'
// 菜单分类标识
export const menuFlag = 'menu'
// 页面分割标识
export const pageSplitStr = '__'
// 统一管理台code
export const adminUnifyTag = 'pagoda_admin'
// 首页比较特殊，必须第一时间允许跳转
export const noPermitPaths = ['/']
// 收藏列表最大限制数量
export const favoriteMax = 50
// 公告和热门问题最大显示数量
export const noticeNumber = 6
