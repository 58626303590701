const state = {
  containLoading: false,
  sideMenu: [
    {
      icon: 'el-icon-user-solid',
      label: '客户',
      subMenu: [
        {
          label: '客户管理',
          url: '/customerManage'
        },
        // {
        //   label: '标签管理',
        //   url: '/iframe/customer/crop_tags'
        // },
        {
          label: '服务标签管理',
          url: '/iframe/customer/service_tags'
        },
        {
          label: '总部标签管理',
          url: '/iframe/customer/hq_tags'
        },
        {
          label: '自定义信息',
          url: '/iframe/customer/customer_field'
        },
        {
          label: '泛客户管理',
          url: '/iframe/customer/user_list'
        },
        {
          label: '自由标签',
          url: '/iframe/tag_act_record/list'
        }
      ]
    },
    {
      icon: 'el-icon-user-solid',
      label: '社群',
      subMenu: [
        {
          label: '社群管理',
          url: '/communityManage'
        },
        {
          label: '群欢迎语素材',
          url: '/iframe/community/customer_welcome_msgs/group_list'
        }
      ]
    },
    {
      icon: 'el-icon-wind-power',
      label: '引流',
      subMenu: [
        {
          label: '欢迎语列表',
          url: '/iframe/drainage/cw_customer_welcome_msgs'
        },
        {
          label: '成员活码',
          url: '/iframe/drainage/member_code'
        },
      ]
    },
    {
      icon: 'el-icon-s-promotion',
      label: '触达',
      subMenu: [
        {
          label: '导购素材库',
          url: '/guideMaterialStore'
        },
        {
          label: '素材分组管理',
          url: '/materialGroupManage'
        },
        {
          label: '营销建议',
          url: '/MarketProposal'
        },
        {
          label: '话术库',
          url: '/SpeechcraftStore'
        },
        {
          label: '话术分组管理',
          url: '/speechcraftGroupManage'
        }
      ]
    },
    {
      icon: 'el-icon-s-promotion',
      label: '团队',
      subMenu: [
        {
          label: '组织架构',
          url: '/iframe/team/cw_members'
        }
      ]
    },
    {
      icon: 'el-icon-s-promotion',
      label: '小程序商城',
      subMenu: [
        {
          label: '商品管理',
          url: '/iframe/mini_shop/goods'
        },
        {
          label: '订单管理',
          url: '/iframe/mini_shop/orders'
        },
        {
          label: '小程序会员管理',
          url: '/iframe/mini_shop/union_customers'
        }
      ]
    },
    {
      icon: 'el-icon-s-promotion',
      label: '风控',
      subMenu: [
        {
          label: '会话存档',
          url: '/SessionArchiving'
        }
      ]
    },
    {
      icon: 'el-icon-s-promotion',
      label: '内部配置',
      subMenu: [
        {
          label: '事件配置',
          url: '/iframe/config/event_config'
        }
      ]
    },
    {
      icon: 'el-icon-user-solid',
      label: '主体',
      subMenu: [
        {
          label: '主体管理',
          url: '/iframe/tag_subject/data_list'
        }
      ]
    }
  ]
}
const getters = {
  containLoading: (state) => state.containLoading,
  sideMenu: (state) => state.sideMenu
}
const mutations = {
  setSideMenu(state, data) {
    state.sideMenu = data || []
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
