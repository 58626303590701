import $ajaxService from './ajax.service'
// import $userService from './user.service'
import $cacheService from './cache.service'
export default {
  // install (Vue) {
  //   /**
  //    * 注册service， 维护公用业务逻辑
  //    */
  //   // Vue.prototype.AjaxService = ajaxService
  //   // Vue.prototype.CommonService = commonService
  //   Vue.prototype.$ajaxService = $ajaxService
  //   Vue.prototype.$userService = $userService
  //   Vue.prototype.$cacheService = $cacheService
  // },
  $ajaxService,
  // $userService,
  $cacheService
}
