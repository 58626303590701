export default [
  {
    path: '/iframe/config/event_config',
    name: 'iframe-config-event_config',
    meta: {
      iframeSrc: '/event_configs',
      iframeName: '事件配置'
    }
  }
]
