export default [
  {
    path: '/iframe/reach/group-list',
    name: 'iframe-reach-group-list',
    meta: {
      iframeSrc: '/cw_groupchats',
      iframeName: '客户群管理'
    }
  },
  {
    path: '/iframe/reach/welcome-speech',
    name: 'iframe-reach-welcome-speech',
    meta: {
      iframeSrc: '/cw_customer_welcome_msgs/group_list',
      iframeName: '客户群欢迎语'
    }
  },
  {
    path: '/reach/customer-group',
    name: 'reach-customer-group',
    meta: {
      title: '客户群管理'
    },
    component: () => import('../views/reach/customerGroup/index')
  },
  {
    path: '/reach/group-welcome',
    name: 'reach-group-welcome',
    meta: {
      title: '客户群欢迎语'
    },
    component: () => import('../views/reach/groupWelcome/index')
  },
  {
    path: '/guideMaterialStore',
    name: 'guideMaterialStore',
    meta: {
      title: '导购素材库'
    },
    component: () => import('../views/reach/guideMaterialStore/index')
  },
  {
    path: '/guideMaterialStoreEdit',
    name: 'guideMaterialStoreEdit',
    meta: {
      title: '导购素材编辑页'
    },
    component: () => import('../views/reach/guideMaterialStore/edit')
  },
  {
    path: '/materialGroupManage',
    name: 'materialGroupManage',
    meta: {
      title: '素材分组管理'
    },
    component: () => import('../views/reach/materialGroupManage/index')
  },
  {
    path: '/marketProposal',
    name: 'marketProposal',
    meta: {
      title: '营销建议'
    },
    component: () => import('../views/reach/marketProposal/index')
  },
  {
    path: '/marketProposalEdit',
    name: 'marketProposalEdit',
    meta: {
      title: '营销建议编辑页'
    },
    component: () => import('../views/reach/marketProposal/edit')
  },
  {
    path: '/speechcraftGroupManage',
    name: 'speechcraftGroupManage',
    meta: {
      title: '话术分组管理'
    },
    component: () => import('../views/reach/speechcraftGroupManage/index')
  },
  {
    path: '/speechcraftStore',
    name: 'speechcraftStore',
    meta: {
      title: '话术库'
    },
    component: () => import('../views/reach/speechcraftStore/index')
  },
  {
    path: '/speechcraftStoreEdit',
    name: 'speechcraftStoreEdit',
    meta: {
      title: '话术编辑页'
    },
    component: () => import('../views/reach/speechcraftStore/edit')
  }
]
