import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import customer from './modules/customer'
import searchInfo from './modules/search-info'
import sideMenu from './modules/menu-data'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    customer,
    searchInfo,
    sideMenu
  }
})
