const state = {
  userInfo: {
    token: ''
  }
}
const getters = {
  userInfo: (state) => state.userInfo
}
const mutations = {
  setUserInfo(state, info) {
    state.userInfo = info || {}
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
