<template>
  <div class="mvp-inline-iframe" v-show="src">
    <iframe
      v-show="iframeRoute.src === src"
      @load="handleLoad"
      :src="yinliuUrl + iframeRoute.src"
      frameborder="0"
      v-for="iframeRoute in iframeRouteList"
      :key="iframeRoute.src"
    ></iframe>
  </div>
</template>

<script>
import envConfig from '@env-config'
export default {
  name: 'inline-iframe',
  data() {
    return {
      yinliuUrl: envConfig.yinliuUrl,
      // 所有需要换成的iframe数组
      iframeRouteList: [],
      src: '' // 当前显示iframeSrc
    }
  },
  props: {
    include: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(n) {
        const prefixName = n.name
        this.setRouterIframeUrl(n)
        if (
          this.src &&
          !this.iframeRouteList.find((item) => item.name === prefixName)
        ) {
          this.iframeRouteList.push({ src: this.src, name: prefixName })
        }
      }
    },
    include: {
      // immediate: true,
      handler(n) {
        this.iframeRouteList = this.iframeRouteList.filter(
          (el) => n.indexOf(el.name) !== -1
        )
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleLoad() {
      // this.$store.commit('setLoading', false)
      console.log('iframe加载完毕')
    },
    setRouterIframeUrl(route) {
      this.src = (route.meta && route.meta.iframeSrc) || ''
    }
  },
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/* 2019-4-29 09:45:45 统一项目所有iframe样式 */
.mvp-inline-iframe {
  height: 100%;
  min-height: 300px;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  > iframe {
    width: 100%;
    flex: 1;
  }
}
</style>
