import Vue from 'vue'
import Router from 'vue-router'
import routes from './router'
import { isFunction } from '@/utils'

Vue.use(Router)

const router = new Router({
  routes: routes.map((el) => {
    if (isFunction(el.component)) {
      let reqFn = el.component
      el.component = (r) =>
        reqFn().then((module) => {
          // 根据录用名字动态定义组件名字
          module.default.name = el.name
          r(module)
        })
    }
    return el
  })
})

router.beforeEach((to, from, next) => {
  let path = to.path
  let username = localStorage.getItem('username')
  if (path === '/login') {
    next()
  } else {
    let token = localStorage.getItem('token')
    // 如果存在token并且username存在的情况下,路由跳转;
    if (token && username) {
      next()
    } else {
      next('/login')
      localStorage.removeItem('token')
      localStorage.removeItem('username')
    }
  }
  console.log('router.beforeEach', to)
  // return await canUserAccess(to)
})

export default router
