<template>
  <pagoda-layout
    :side-menu="sideMenu"
    :user-info="userInfo"
    :on-logout="handleLogout"
    :on-operate-item-click="handleOperateItemClick"
    @tabs-data-change="handleTabsDataChange"
    @tabs-change="handleTabsChange"
  >
    <!-- 头部的logo名称插槽 -->
    <div slot="header-left">
      <span class="logo-text">微客运营中心管理台</span>
    </div>
    <mvp-inline-iframe :include="inlineInclude"></mvp-inline-iframe>
    <keep-alive :include="include">
      <router-view v-loading="containLoading"></router-view>
    </keep-alive>
  </pagoda-layout>
</template>

<script>
import http from '@/services/api/common'
import { iframeRoutes } from '@/router/iframe'
// iframe内嵌页做页面缓存
const iframeNames = iframeRoutes.map((route) => route.name)
export default {
  name: 'App',
  data() {
    return {
      inlineInclude: [],
      include: []
    }
  },
  computed: {
    userInfo() {
      return {
        userName: localStorage.getItem('username') || ''
      }
    },
    containLoading() {
      return this.$store.getters.containLoading
    },
    sideMenu() {
      return this.$store.getters.sideMenu
    }
  },
  created() {},
  methods: {
    handleLogout() {
      this.$router.push('/login')
      const token = this.$store.getters.userInfo.token
      http
        .loginOut(token)
        .then((res) => {
          localStorage.removeItem('token')
          this.$router.push('/login')
        })
        .catch(() => {})
    },
    handleOperateItemClick(operate) {
      // 当前点击的操作项配置
      console.log(operate)
    },
    handleTabsChange({ url, query = {}, params = {} }) {
      console.log('232132132131')
      this.$router.push({
        path: url,
        query,
        params
      })
    },
    handleTabsDataChange(tabs) {
      this.inlineInclude = tabs
        .filter((tab) => iframeNames.indexOf(tab.name) !== -1)
        .map((tab) => tab.name)
      this.include = tabs.map((tab) => tab.name)
    }
  }
}
</script>
<style lang="less">
.pagoda-layout .header-slot--left {
  min-width: 187px !important;
}
.pagoda-layout .pagoda-layout__avatar {
  display: none;
}
.el-dropdown-menu.pagoda-layout-user-info .info-item__label {
  display: none;
}
</style>
