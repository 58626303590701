export default [
  {
    path: '/iframe/drainage/cw_customer_welcome_msgs',
    name: 'iframe-drainage-cw_customer_welcome_msgs',
    meta: {
      iframeSrc: '/cw_customer_welcome_msgs',
      iframeName: '欢迎语列表'
    }
  },
  {
    path: '/iframe/drainage/member_code',
    name: 'iframe-drainage-member_code',
    meta: {
      iframeSrc: '/member_code',
      iframeName: '成员活码'
    }
  }
]
