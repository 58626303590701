const state = {
  materialTypeList: []
}
const getters = {
  materialTypeList: (state) => state.materialTypeList
}
const mutations = {
  // 素材类型
  MATERIAL_TYPE(state, info) {
    state.materialTypeList = info || {}
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
