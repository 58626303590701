export default [
  {
    path: '/iframe/customer/crop_tags',
    name: 'iframe-customer-crop_tags',
    meta: {
      iframeSrc: '/crop_tags',
      iframeName: '标签列表'
    }
  },
  {
    path: '/iframe/customer/service_tags',
    name: 'iframe-customer-service_tags',
    meta: {
      iframeSrc: '/tags?type=SERVICE',
      iframeName: '服务标签'
    }
  },
  {
    path: '/iframe/customer/hq_tags',
    name: 'iframe-customer-hq_tags',
    meta: {
      iframeSrc: '/tags?type=HQ',
      iframeName: '总部标签'
    }
  },
  {
    path: '/customerManage',
    name: 'customerManage',
    meta: {
      title: '客户管理'
    },
    component: () => import('../views/customer/customerManage/index')
  },
  {
    path: '/customerManageDetail',
    name: 'customerManageDetail',
    meta: {
      title: '客户详情页'
    },
    component: () => import('../views/customer/customerManage/detail')
  },
  {
    path: '/iframe/customer/customer_field',
    name: 'iframe-customer-customer-field',
    meta: {
      iframeSrc: '/customer_field/list',
      iframeName: '自定义信息'
    }
  },
  {
    path: '/iframe/customer/user_list',
    name: 'iframe-customer-user-list',
    meta: {
      iframeSrc:
        '/tag_subject_user/list?token=' + window.localStorage.getItem('token'),
      iframeName: '泛客户管理'
    }
  },
  {
    path: '/iframe/tag_subject/data_list',
    name: 'iframe-tag-subject-data-list',
    meta: {
      iframeSrc:
        '/tag_subjects/list?token=' + window.localStorage.getItem('token'),
      iframeName: '主体管理'
    }
  },
  {
    path: '/iframe/tag_act_record/list',
    name: 'iframe-tag-act-record-list',
    meta: {
      iframeSrc:
        '/tag_act_records?token=' + window.localStorage.getItem('token'),
      iframeName: '自由标签'
    }
  }
]
