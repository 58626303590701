export default [
  {
    path: '/sessionArchiving',
    name: 'sessionArchiving',
    meta: {
      title: '会话存档'
    },
    component: () => import('../views/reach/sessionArchiving/index')
  }
]
