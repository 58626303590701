import customerRoutes from './customer'
import communityRoutes from './community'
import drainageRoutes from './drainage'
import reachRoutes from './reach'
import teamRoutes from './team'
import minishopRoutes from './mini_shop'
import sessionRoutes from './session'
import configRoutes from './config'
// 找出所有iframe路由数组
export const iframeRoutes = customerRoutes
  .concat(
    communityRoutes,
    drainageRoutes,
    reachRoutes,
    teamRoutes,
    minishopRoutes,
    sessionRoutes,
    configRoutes
  )
  .filter((route) => route.meta && route.meta.iframeSrc)
