// 自定义布局header
<template>
  <div class="pagoda-detail-layout-v1__bar self-header-box">
    <div class="detail-v1-back" @click="handleBackToList">返回主列表</div>
    <div class="detail-v1-center">{{ contentTitle }}</div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'layoutHeader',
  props: {
    contentTitle: String // 页面名称
  },
  data() {
    return {}
  },
  methods: {
    handleBackToList() {
      this.$emit('backToList')
    }
  }
}
</script>
