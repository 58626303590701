export const getDate = () => {
  let now = new Date()
  return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
}
// 获取某一天的前几天或者后几天 的日期对象 date = 某一天， n = 相差天数
export const getOtherDate = (param = {}) => {
  let datetime = +param.date || Date.now()
  let n = param.n || 0
  let newDate = new Date(datetime + n * 24 * 60 * 60 * 1000)
  // newDate.setDate(date.getDate() + n)
  return newDate
}
// 2020-3-12 16:27:13 获取某一天的前几天或者后几天 的日期时间戳 date = 某一天， n = 相差天数
export const getOtherDatetime = (param = {}) => {
  let datetime = +param.date || Date.now()
  let n = param.n || 0
  return datetime + n * 24 * 60 * 60 * 1000
}
// 获取当月第一天
export const getMonthFirstDate = (date = new Date()) => {
  let newDate = new Date(+date)
  newDate.setDate(1)
  return newDate
}
// 获取当月最后一天
export const getMonthLastDate = (date = new Date()) => {
  let newDate = new Date(+date)
  newDate.setMonth(newDate.getMonth() + 1)
  newDate.setDate(0)
  return newDate
}
// element-ui 日期组件禁用日期工具函数，n < 0 则禁用date之前的日期，n > 0 则禁用date之后的日期
export const getPickerOptions = (param = {}) => {
  let date = param.date || new Date()
  let n = param.n || 0
  // 禁用之前日期需提前一天
  if (n < 0) date = getOtherDate({ date, n: -1 })
  return {
    disabledDate(time) {
      return n < 0 ? +time < +date : +time > +date
    }
  }
}
// 把date时间设置为00:00:00
export const setDateTime0 = (date) => {
  date.setHours(0, 0, 0, 0)
  return date
}
// 把[date1, date2]的时间设置为 00:00:00 ~ 23:59:59
export const setDateRangeTime0 = (dates) => {
  return dates.map((date, i) => {
    if (i === 0) {
      date.setHours(0, 0, 0, 0)
    } else {
      date.setHours(23, 59, 59, 0)
    }
    return date
  })
}

export const timestampToTime = (timestamp) => {
  const date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  const strDate = Y + M + D + h + m + s
  return strDate
}

/*
  获取element日期组件shortcuts方法 start
  方法命名规则如下：
  start: 月第一天
  end: 月最后一天
  yesterday: 昨天
  today: 今天
  tomorrow: 明天
  l: 往前月
  c: 当月
  n: 往后后
  s: 代表返回范围数组
 */
// 单日 date 和 datetime
export const getShortcutYesterday = () => {
  return {
    text: '昨天',
    onClick(picker) {
      picker.$emit('pick', setDateTime0(getOtherDate({ n: -1 })))
    }
  }
}
export const getShortcutToday = () => {
  return {
    text: '今天',
    onClick(picker) {
      picker.$emit('pick', setDateTime0(new Date()))
    }
  }
}
export const getShortcutTomorrow = () => {
  return {
    text: '明天',
    onClick(picker) {
      picker.$emit('pick', setDateTime0(getOtherDate({ n: 1 })))
    }
  }
}
export const getShortcutCStart = () => {
  return {
    text: '月初',
    onClick(picker) {
      picker.$emit('pick', setDateTime0(getMonthFirstDate()))
    }
  }
}
export const getShortcutCEnd = () => {
  return {
    text: '月末',
    onClick(picker) {
      picker.$emit('pick', setDateTime0(getMonthLastDate()))
    }
  }
}
// 范围 daterange ~ datetimerange
export const getShortcutsToday = () => {
  const today = new Date()
  return {
    text: '今天',
    onClick(picker) {
      picker.$emit('pick', setDateRangeTime0([today, today]))
    }
  }
}
export const getShortcutsTomorrow = () => {
  return {
    text: '明天',
    onClick(picker) {
      picker.$emit(
        'pick',
        setDateRangeTime0([getOtherDate({ n: 1 }), getOtherDate({ n: 1 })])
      )
    }
  }
}
export const getShortcutsCStartToday = () => {
  return {
    text: '当月',
    onClick(picker) {
      picker.$emit('pick', setDateRangeTime0([getMonthFirstDate(), new Date()]))
    }
  }
}
export const getShortcutsL7 = () => {
  return {
    text: '最近一周',
    onClick(picker) {
      picker.$emit(
        'pick',
        setDateRangeTime0([getOtherDate({ n: -6 }), new Date()])
      )
    }
  }
}
export const getShortcutsL30 = () => {
  return {
    text: '最近30天',
    onClick(picker) {
      picker.$emit(
        'pick',
        setDateRangeTime0([getOtherDate({ n: -29 }), new Date()])
      )
    }
  }
}
export const getShortcutsL90 = () => {
  return {
    text: '最近90天',
    onClick(picker) {
      picker.$emit(
        'pick',
        setDateRangeTime0([getOtherDate({ n: -89 }), new Date()])
      )
    }
  }
}
/* 获取element日期组件shortcuts方法 end */
/* 2019-11-11 14:05:03 整合与日期相关的函数，并整体抽离date.js，后续相关函数请一起迁移 end */
export const limitRangeDay = 91
export const limitDaterangePickerOptions = (options) => {
  let { day = limitRangeDay, disabledFn } = options
  let dateRange = {
    minDate: null,
    maxDate: null
  }
  let shortcuts
  const currentMonth = getShortcutsCStartToday()
  const day7 = getShortcutsL7()
  const day30 = getShortcutsL30()
  const day90 = getShortcutsL90()
  if (options.shortcuts) {
    shortcuts = options.shortcuts
  } else if (day < 7) {
    shortcuts = undefined
  } else if (day < 30) {
    shortcuts = [day7]
  } else if (day < 90) {
    shortcuts = [currentMonth, day7, day30]
  } else {
    shortcuts = [currentMonth, day7, day30, day90]
  }
  if (options.extraShortcuts) {
    shortcuts = [...options.extraShortcuts, ...shortcuts]
  }
  return {
    disabledDate: (date) => {
      const dateTime = +date
      if (dateRange.minDate && !dateRange.maxDate) {
        let minDateTime = +dateRange.minDate
        // 设置允许范围
        const minTime = getOtherDatetime({ date: minDateTime, n: -day })
        const maxTime = getOtherDatetime({ date: minDateTime, n: day })
        return (
          dateTime < +minTime ||
          dateTime > +maxTime ||
          (disabledFn && disabledFn(dateTime))
        )
      } else {
        return disabledFn && disabledFn(dateTime)
      }
    },
    onPick: ({ maxDate, minDate }) => {
      dateRange.minDate = minDate
      dateRange.maxDate = maxDate
    },
    shortcuts
  }
}
