import customerRoutes from './customer'
import communityRoutes from './community'
import drainageRoutes from './drainage'
import reachRoutes from './reach'
import teamRoutes from './team'
import minishopRoutes from './mini_shop'
import sessionRoutes from './session'
import configRoutes from './config'
import layoutComponents from '@/views/layout'
const routes = [
  {
    name: 'layout',
    path: '/',
    redirect: '/home',
    component: layoutComponents,
    children: [
      {
        name: 'home',
        path: '/home',
        component: () => import('../views/index')
      },
      ...customerRoutes,
      ...communityRoutes,
      ...drainageRoutes,
      ...reachRoutes,
      ...teamRoutes,
      ...minishopRoutes,
      ...sessionRoutes,
      ...configRoutes
    ]
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/login/index')
  }
]

export default routes
