export default [
  {
    path: '/iframe/mini_shop/goods',
    name: 'iframe-mini_shop-goods',
    meta: {
      iframeSrc: '/goods',
      iframeName: '商品管理'
    }
  },
  {
    path: '/iframe/mini_shop/orders',
    name: 'iframe-iframe-mini_shop-orders',
    meta: {
      iframeSrc: '/orders',
      iframeName: '订单管理'
    }
  },
  {
    path: '/iframe/mini_shop/union_customers',
    name: 'iframe-iframe-mini_shop-union_customers',
    meta: {
      iframeSrc: '/union_customers',
      iframeName: '订单管理'
    }
  }
]
