/**
 * 这是ajax请求相关的服务
 */
import axios from 'axios'
import { Message, Loading } from 'element-ui'
import envConfig from '@env-config'
import router from '@/router'
// import { login, logout } from '@/util/auth'
import http from 'pagoda-ui/src/utils/http'
import { login } from '@/utils/auth'
// import { trimParams, noEmptyParams } from '@/utils'
import $store from '../store'

const instance = axios.create({
  // baseURL: '',
  timeout: 120000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'x-client-ajax': '1'
  }
})
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // const isGet = config.method.toUpperCase() === 'GET'
    // let params = isGet ? config.params : config.data
    config.headers.token = localStorage.getItem('token') || ''
    // // 默认执行去空格 可通过options屏蔽
    // if (config.trim !== false) {
    //   if (isGet) {
    //     config.params = trimParams(params)
    //   } else {
    //     config.data = trimParams(params)
    //   }
    // }
    // // 字符串、数组、对象为空时不传给后端
    // if (config.noEmpty) {
    //   if (isGet) {
    //     config.params = noEmptyParams(params)
    //   } else {
    //     config.data = noEmptyParams(params)
    //   }
    // }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  function (response) {
    if (response && response.data.code !== 200) {
      if (response && response.data.code === 401) {
        router.push('/login')
        return
      } else {
        Message.error(response.data.msg)
      }
    }
    if (
      response.headers.sessionstatus === 'timeout' ||
      response.headers.Sessionstatus === 'timeout'
    ) {
      console.log('没有权限tiemout')
      login(envConfig.baseUrl)
      // window.location.href = window.location.origin + '/rbac-backend/login' // 登录超时，跳转单点登录链接
    }
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response && error.response.status === 418) {
      console.log('没有权限418')
      login(envConfig.baseUrl)
    }
    // Do something with response error
    // Message.error('网络异常，请重试'
    Message.error(error)
    return Promise.reject(error)
  }
)

// 2018-9-26 11:14:57 重置组件库的instance
http.axiosInstance = instance

class AjaxService {
  constructor() {
    this.name = 'AjaxService'
    this.xhrQueue = {} // ajax请求队列
    Object.assign(this, envConfig)
  }
  queue(_id, _promise) {
    // 负责存队列
    // key: 请求的url param method组成的string ; value: 请求promise
    this.xhrQueue[_id] = _promise
    return _id
  }
  queueCleaner(id) {
    // 删除队列中某个source
    delete this.xhrQueue[id]
  }
  xhrCancel() {
    // 清除所有队列
    Object.keys(this.xhrQueue).forEach((id) => {
      let source = this.xhrQueue[id]
      source.cancel() // 退订
    })
  }
  request({ method, url, params = null, options = {} }) {
    switch (method.toUpperCase()) {
      case 'GET':
        options.params = params
        break
      case 'POST':
        options.data = params
        break
    }
    if (options.containLoading) {
      $store.store.containLoading = true
      // this.$store.commit('setLoadingFull', true)
    }
    let loading
    if (options.loading) {
      loading = Loading.service({
        lock: true,
        background: 'transparent'
      })
    }
    const CancelToken = axios.CancelToken
    let sourceCancel
    const config = Object.assign(
      {
        url,
        method,
        cancelToken: new CancelToken(function executor(cancel) {
          // executor 函数接收一个 cancel 函数作为参数
          sourceCancel = cancel
        })
      },
      options
    )
    /* console.log(config) */
    const _id = JSON.stringify({ url, params, method })
    if (_id in this.xhrQueue) return this.xhrQueue[_id]
    const requestPromise = new Promise((resolve, reject) => {
      // 成功回调
      const successCb = (res) => {
        let resultCode = res.data.resultCode || res.data.code || 0
        let errorMsg = res.data.errorMsg || res.data.message || ''
        switch (resultCode) {
          case 200:
            resolve(res.data)
            break
          case 500:
            // resultCode 500 采用error提示
            !options.noErrorMsg &&
              Message.error(errorMsg && errorMsg.substr(0, 200))
            reject(res.data)
            break
          default:
            reject(res.data)
            break
        }
      }
      // 失败回调
      const errorCb = (error) => {
        if (config.retry) {
          config.retry -= 1
          instance.request(config).then(successCb).catch(errorCb)
        } else {
          reject(error)
        }
      }
      instance.request(config).then(successCb).catch(errorCb)
    })
    // 给promise对象添加取消axios请求的方法
    requestPromise.cancel = sourceCancel
    this.queue(_id, requestPromise) // 存进队列
    return requestPromise.finally(() => {
      this.queueCleaner(_id) // 释放队列中的subscription
      if (options.containLoading) {
        $store.store.containLoading = false
        // this.$store.commit('setLoadingFull', true)
      }
      if (options.loading) {
        loading.close()
      }
    })
  }

  ajax(method, url, params, options) {
    return this.request({ method, url, params, options })
  }

  get(url, params, options) {
    return this.request({ method: 'GET', url, params, options })
  }

  post(url, params, options) {
    return this.request({ method: 'POST', url, params, options })
  }

  setHeaderAuthorization(token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}
export default new AjaxService()
