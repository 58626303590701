<template>
  <div id="app" style="height: 100%">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss"></style>
