export const deepCopy = (data) => JSON.parse(JSON.stringify(data))

export const noop = () => () => { }

export const isArray = (target) => {
  return toString.call(target) === '[object Array]'
}

export const isObject = (target) => {
  return toString.call(target) === '[object Object]'
}

export const isPromise = (target) => {
  return toString.call(target) === '[object Promise]'
}

export const isString = (target) => {
  return toString.call(target) === '[object String]'
}

export const isNumber = function (val) {
  let isNumberRes = true

  if (Number.isNaN(val)) {
    isNumberRes = false
  } else if (typeof val !== 'number') {
    isNumberRes = false
  }

  return isNumberRes
}

export const isUndefined = (target) => {
  return toString.call(target) === '[object Undefined]'
}

export const isFunction = (target) => {
  return toString.call(target) === '[object Function]'
}

export const isEmpty = (target) => {
  if (isUndefined(target)) {
    return true
  } else if (isString(target)) {
    return target.trim().length === 0
  } else if (isObject(target)) {
    return Object.keys(target).length === 0
  } else if (isArray(target)) {
    return target.length === 0
  }
  return false
}

/*
* 函数防抖
*
* @action {Function} 要执行的动作
* @delay  {Number}  执行动作的延迟时间
* @return {Function} 包装后的动作
*
*/
export const bounce = function (action, delay) {
  let timmer

  return function (...arg) {
    const context = this
    clearTimeout(timmer)

    timmer = setTimeout(function () {
      action.apply(context, arg)
    }, delay)
  }
}

export const methodsProxy = function (targetInstance, originalInstance, methodsMap) {
  methodsMap.forEach(methodName => {
    const method = originalInstance[methodName]

    if (method && typeof method === 'function') {
      if (targetInstance[methodName]) {
        console.error(`Error in methodsProxy：方法名${methodName}已存在于目标实例中`)
        return
      }

      targetInstance[methodName] = method
    }
  })
}

export const eventsProxy = function (targetInstance, originalInstance, eventsMap) {
  eventsMap.forEach(eventName => {
    if (!originalInstance._events[eventName]) {
      originalInstance._events[eventName] = []
    }

    originalInstance._events[eventName].push(function (...arg) {
      targetInstance.$emit(eventName, ...arg)
    })
  })
}

// 根据key从数组中筛选对象
export const findObjFromArray = (arr, key, keyName) => {
  return arr.find(item => item[keyName] === key)
}

// 去重去空
export const filterRepeat = (array) => {
  return array.filter((el, i, arr) => el && arr.indexOf(el) === i)
}
// 根据对象的某个key值去重
export const filterObjRepeat = function (arr, key) {
  let arrTmpl = []
  return arr.filter(el => { // 根据对象的name值去重。
    return arrTmpl.indexOf(el[key]) === -1 ? arrTmpl.push(el[key]) : false
  })
}

// 把一维数组切割为二维数组
export const preArrayMultiple = (srcArr, column = 3, defaultValue = {}) => {
  let bigArr = []
  srcArr.forEach((el, i) => { // 把大数组分割成多个数组，每个数组有n项
    let index = parseInt(i / column)
    bigArr[index] = bigArr[index] ? bigArr[index] : []
    bigArr[index].push(el)
  })
  let last = bigArr.length - 1
  while (bigArr[last] && bigArr[last].length < column) { // 把最后一数组填满n项
    bigArr[bigArr.length - 1].push(defaultValue) // 默认填充
  }
  return bigArr
}
export const jsonParse = (str) => {
  try {
    return isArray(str) ? str : JSON.parse(str || '[]')
  } catch (e) {
    return []
  }
}
export const getlocalStorageObj = (str) => {
  return JSON.parse(localStorage.getItem(str) || '[]')
}
export const setlocalStorageObj = (str, obj) => {
  localStorage.setItem(str, JSON.stringify(obj))
  return obj
}
export const formatDate = (fdate, formatStr = 'y-m-d h:i:s') => {
  if (fdate) {
    let fStr = 'ymdhis'
    let month = fdate.getMonth() + 1
    let day = fdate.getDate()
    let hours = fdate.getHours()
    let minu = fdate.getMinutes()
    let second = fdate.getSeconds()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    hours = hours < 10 ? ('0' + hours) : hours
    minu = minu < 10 ? '0' + minu : minu
    second = second < 10 ? '0' + second : second
    let formatArr = [
      fdate.getFullYear().toString(),
      month.toString(),
      day.toString(),
      hours.toString(),
      minu.toString(),
      second.toString()
    ]
    for (var i = 0; i < formatArr.length; i++) {
      formatStr = formatStr.replace(fStr.charAt(i), formatArr[i])
    }
    return formatStr
  } else {
    return ''
  }
}
// 对Object/Array的数据判断当字符串、数组、对象为空时不传给后端
export const noEmptyParams = (data) => {
  if (!data) return data
  let copyData = {}
  Object.keys(data).forEach(key => {
    if (!isEmpty(data[key])) { // 为空
      copyData[key] = data[key]
    }
  })
  return copyData
}
// 对Object/Array的数据递归去空格，用了递归小心爆炸(* ￣3)(ε￣ *)
export const trimParams = (data) => {
  if (!data) return data
  let copyData = deepCopy(data)
  Object.keys(copyData).forEach(key => {
    if (isString(copyData[key])) { // string
      copyData[key] = copyData[key].trim()
    }
  })
  return copyData
}
