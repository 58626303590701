export default [
  {
    path: '/iframe/team/cw_members',
    name: 'iframe-team-cw_members',
    meta: {
      iframeSrc: '/cw_qw_members',
      iframeName: '组织架构'
    }
  }
]
