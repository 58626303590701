export const login = (baseUrl) => {
  const queryUrl = baseUrl || window.location.origin
  const href = window.location.href
  window.location.href = `${queryUrl}/api/login?url=${encodeURIComponent(href)}` // 登录超时，跳转单点登录链接
}
export const logout = (baseUrl) => {
  const queryUrl = baseUrl || window.location.origin
  const origin = window.location.origin
  const pathname = window.location.pathname
  window.location.href = `${queryUrl}/api/logout?url=${encodeURIComponent(origin + pathname)}` // 登录超时，退出登录
}
