var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"erp-request-select",class:{
    'advanced-search': _vm.advancedSearch,
    'remote': _vm.remote
  }},[_c('el-select',_vm._g(_vm._b({ref:"elSelect",class:{
      'is-focus': _vm.isFocus
    },style:({
      'min-width': (_vm.remote && _vm.multiple) ? '200px' : 'unset'
    }),attrs:{"value":_vm.value,"placeholder":_vm.placeholderText,"default-first-option":"","multiple":_vm.multiple,"value-key":_vm.valueKey,"label-key":_vm.labelKey,"remote":!!_vm.remote,"remote-method":_vm.handleRemote,"filterable":!!_vm.remote || _vm.filterable,"disabled":_vm.loading || _vm.disabled,"loading":_vm.loading},on:{"remove-tag":_vm.handleRemoveTag,"visible-change":_vm.handleVisibleChange}},'el-select',_vm.$attrs,false),_vm.listeners),[(_vm.group)?_vm._l((_vm.options),function(group){return _c('el-option-group',{key:group.label,attrs:{"label":group.label}},_vm._l((group.options),function(option){return _c('el-option',{key:_vm.valueKey ? option[_vm.valueKey] : option.value,attrs:{"label":_vm.labelKey? option[_vm.labelKey] : option.label,"value":option,"disabled":option.disabled}},[_vm._t("default",[_vm._v(_vm._s(_vm.selectSlotCustom(option)))],null,option)],2)}),1)}):_vm._l((_vm.options),function(option){return _c('el-option',{key:_vm.valueKey ? option[_vm.valueKey] : option.value,attrs:{"label":_vm.labelKey? option[_vm.labelKey] : option.label,"value":option,"disabled":option.disabled}},[_vm._t("default",[_vm._v(_vm._s(_vm.selectSlotCustom(option)))],null,option)],2)})],2),(_vm.advancedSearch)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"el-input__suffix",on:{"click":function($event){$event.stopPropagation();return _vm.handleAdvancedSearch($event)}}},[_vm._m(0)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"el-input__suffix-inner"},[_c('i',{staticClass:"el-input__icon el-icon-more"})])}]

export { render, staticRenderFns }