const state = {
  customerManage: {}
}
const getters = {
  customerManage: (state) => state.customerManage
}
const mutations = {
  // 客户管理
  CUSTOMER_MANAGE(state, info) {
    state.customerManage = info || {}
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
