export default [
  {
    path: '/communityManage',
    name: 'communityManage',
    meta: {
      title: '社群管理'
    },
    component: () => import('../views/community/communityManage/index')
  },
  {
    path: '/communityManageDetail',
    name: 'communityManageDetail',
    meta: {
      title: '社群详情页'
    },
    component: () => import('../views/community/communityManage/detail')
  },
  {
    path: '/iframe/community/customer_welcome_msgs/group_list',
    name: 'iframe-community-customer_welcome_msgs-group_list',
    meta: {
      iframeSrc: '/customer_welcome_msgs/group_list',
      iframeName: '群欢迎语素材'
    }
  }
]
