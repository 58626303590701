/* 安装业务逻辑服务 */
import $services from './services'
import CustomerService from './views/customer/customer.service'
import DrainageService from './views/drainage/drainage.service'
import ReachService from './views/reach/reach.service'
export default {
  install (Vue) {
    /**
     * 注册service， 维护公用业务逻辑
     */
    // Vue.prototype.AjaxService = ajaxService
    // Vue.prototype.CommonService = commonService
    Object.assign(Vue.prototype, $services, {
      CustomerService: new CustomerService($services),
      DrainageService: new DrainageService($services),
      ReachService: new ReachService($services)
    })
  }
}
